<template>
  
    <banner :banner="banner" :caption="caption" :path="bannerPath"></banner>

    <div class="container mt-3">
        <div class="row">
            <div class="col-3 table-menu-left">
                <div class="text-center mt-2 mb-2">
                    <a>
                        <router-link to="/vision_mission">
                            <img src="../../assets/images/visi_misi.png" class="icon-menu">
                        </router-link>
                    </a>
                    <p class="text-white text-menu">VISION & MISSION</p>
                </div>
            </div>

            <div class="col-3 table-menu-right">
                <div class="text-center mt-2 mb-2">
                    <a>
                        <router-link to="/culture">
                            <img src="../../assets/images/budaya.png" class="icon-menu">
                        </router-link>
                    </a>
                    <p class="text-white text-menu">COMPANY CULTURE</p>
                </div>
            </div>

            <div class="col-3 table-menu-left">
                <div class="text-center mt-2 mb-2">
                    <a>
                        <router-link to="/management">
                            <img src="../../assets/images/manajemen.png" class="icon-menu">
                        </router-link>
                    </a>
                    <p class="text-white text-menu">MANAGEMENT RANGE</p>
                </div>
            </div>

            <div class="col-3 table-menu-right">
                <div class="text-center mt-2 mb-2">
                    <a>
                        <router-link to="/governance">
                            <img src="../../assets/images/tata_kelola.png" class="icon-menu">
                        </router-link>
                    </a>
                    <p class="text-white text-menu">GOVERNANCE</p>
                </div>
            </div>
        </div>

        <div class="border-bottom-red"></div>
    </div>

    <div class="container-fluid mt-3 border-bottom-red">
        <div class="row">
            <div class="col-2">
                <div class="content-block">
                    <img class="icon-red img-left" src="../../assets/images/sejarah.png" alt="icon">
                </div>
            </div>

            <div class="col-10">
                <div class="content-block">
                    <h3> <span class="text-primary">A BRIEF HISTORY</span> <span class="text-danger">OF BAHTERA ADHIGUNA</span></h3>
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item text-justify">
                            In 1961, the Government of the Republic of Indonesia nationalized the Dutch-owned company NV Netherlandsch-Indische Steenkolen Handel-Maatschappij (NV NISHM) and changed it to PN Menunda Tundabara, with the main business being coal transportation services and pilotage services. In 1966, the PN Delaying Tugboats was changed to PN Bahtera Adhiguna.
                        </li>
                        <li class="list-group-item text-justify">
                            In 1971, based on government regulations PN Bahtera Adhiguna, it was changed to PT Bahtera Adhiguna (Persero) and its activities are bulk goods and general cargo transportation services, wood transportation, ship agency services, EMKL, loading and unloading from/to ships.
                        </li>
                        <li class="list-group-item text-justify">
                            In 1985, PT Bahtera Adhiguna established a subsidiary, namely, PT PBM Adhiguna Putera which specifically handles loading and unloading from/to ships.
                        </li>
                        <li class="list-group-item text-justify">
                            In March 2011, the Government of the Republic of Indonesia has determined that the addition of the State's equity participation into the share capital of the Company (Persero) PT Perusahaan Listrik Negara comes from the transfer of all shares owned by the State of the Republic of Indonesia to the Company Company (Persero) PT Pelayaran Bahtera Adhiguna.
                        </li>
                        <li class="list-group-item text-justify">
                            In May 2011, the Ministry of State-Owned Enterprises issued a decree regarding the Transfer of Shared Capital of the Republic of Indonesia from the state-owned company (Persero) PT Pelayaran Bahtera Adhiguna to the state-owned company (Persero) PT Perusahaan Listrik Negara.
                        </li>
                        <li class="list-group-item text-justify">
                            In August 2011, there was a signing of the Deed of Transfer of rights to shares owned by the Republic of Indonesia in the Company (Persero) PT Pelayaran Bahtera Adhiguna to the Company (Persero) PT Perusahaan Listrik Negara.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

import Banner from '../banner/banner.vue'
import axios from 'axios'

export default {
    name: 'pageAboutUs',
    components: {
        Banner
    },
    data () {
        return {
            // bannerPath: 'http://localhost:8080/assets/banner/',
            bannerPath: 'https://api.plnbag.co.id/assets/banner/',
            banner: '',
            caption: '',
        }
    },
    mounted () {
        this.loadBanner()
    },
    methods: {
        loadBanner () {
            axios.get('tk_banner')
                .then((res) => {
                    this.banner = res.data.data.banner
                    this.caption = res.data.data.caption_en
                })
        }
    }
}
</script>

<style scoped>
    .table-menu-left {
        background:#0038BA
    }

    .table-menu-right {
        background:#004BFA
    }

    .icon-menu {
        width: 50px;
        height: 50px;
    }

    .img-left {
        width: 85px;
        height: auto;
    }

    @media screen and (max-width: 720px) {
        .img-left {
            width: 45px;
            height: auto;
        }
    }

    @media screen and (max-width: 720px) {
        .text-menu {
            font-size: 10px;
        }
    }

    .content-home .content-block {
        padding: 25px 0 0;
    }

    .content-home .content-block h3 {
        color: #0158a7;
        text-transform: uppercase;
        font-weight: 600;
        margin-bottom: 15px;
        font-size: 17px;
    }

    .content-home .content-block span.title-w {
        color: #fff;
        text-transform: uppercase;
        font-weight: 600;
        margin-left: 10px;
        margin-bottom: 15px;
        font-size: 17px;
    }

    .content-home .content-block p.text-w {
        color: #fff;
        margin-bottom: 20px;
        font-weight: 200;
        text-align: justify;
    }

    .content-home .content-block p {
        color: #404041;
        margin-bottom: 20px;
        font-weight: 200;
        text-align: justify;
    }

    .icon-red {
        background-color: #ea2e23;
        border-radius: 5px;
        padding: 10px;
        float: right;
    }

    @media (max-width: 767px) {
        .icon-red {
            float: none;
        }
    }

    .border-bottom-red {
        border-bottom: 5px solid #ea2e23;
    }

</style>